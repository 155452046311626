import React, { useRef, useState } from 'react'
import Aos from 'aos'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'aos/dist/aos.css'
import { MdEmail } from 'react-icons/md'
import './contact.css'
import { Link } from 'react-router-dom'
import emailjs from '@emailjs/browser';


export default function Contact() {

  const [isSubmitting, setIsSubmitting] = useState(false);

  const form = useRef();

  const sendEmail = (e) => {
    setIsSubmitting(true);
    e.preventDefault();
  

    emailjs
      .sendForm('service_o7y72jj', 'template_zmozbsl', form.current, {
        publicKey: '_ybywiWeaDGGYODeo',
      })
      
      .then(
        () => {
          console.log('SUCCESS!');
          toast.success('Message sent successfully!', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          })
          e.target.reset()
        },
        (error) => {
          console.log('FAILED...', error.text);
          toast.error('Failed to send message. Please try again.', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        },
      )
      .finally(() => {
        setIsSubmitting(false);
      });

  };


  return (
    <>
<div className="container-fluid py-5" data-aos='zoom-in' data-aos-delay="500" >
  <div className="container pt-5">
    <div className="row g-5">
      <div className="col-lg-7">
        <div className="section-title position-relative pb-3 mb-5">
          <h5 className="fw-bold text-primary text-uppercase">Get In Touch</h5>
          <h1 className="mb-0">We'd Love to Hear from You</h1>
        </div>
        <div className="row gx-3">
          <div className="col-sm-6 wow zoomIn" data-wow-delay="0.2s">
            <h5 className="mb-4"><i className="fa fa-reply text-primary me-3" />Quick response within 24 hours</h5>
          </div>
          <div className="col-sm-6 wow zoomIn" data-wow-delay="0.4s">
            <h5 className="mb-4"><i className="fa fa-phone-alt text-primary me-3" />24/7 Customer Support</h5>
          </div>
        </div>
        <p className="mb-4"> We believe in the power of technology to transform businesses. Our team of experts is ready to discuss how we can tailor our cutting-edge solutions to meet your unique needs and drive your success.</p>
        <div className="d-flex align-items-center mt-2 wow zoomIn" data-wow-delay="0.6s">
          <div className="bg-primary d-flex align-items-center justify-content-center rounded" style={{width: 60, height: 60}}>
            <i> <MdEmail className='text-white' /></i>
          </div>
          <div className="ps-4">
            <h5 className="mb-2">Email to ask any question</h5>
            <Link to="mailto:info@makeoversolution.in" className="text-decoration-none">
    <h4 className="text-primary mb-0">info@makeoversolution.in</h4>
  </Link>
          </div>
        </div>
      </div>
      <div className="col-lg-5">
        <div className="bg-primary rounded h-100 d-flex align-items-center p-5 " data-aos='zoom-in'>
          <form ref={form} onSubmit={sendEmail}>
          <h2 className='contact-us'>Contact Us</h2>
            <div className="row g-3">
    
              <div className="col-xl-12"> 
                <input type="text" className="form-control bg-light border-0" placeholder="Your Name" name="from_name" style={{height: 55}} required />
              </div>
              <div className="col-12">
                <input type="email" className="form-control bg-light border-0" placeholder="Your Email" name="from_email" style={{height: 55}}  required />
              </div>
              {/* <div className="col-12">
                <select className="form-select bg-light border-0" style={{height: 55}}>
                  <option selected>Select A Service</option>
                  <option value={1}>Service 1</option>
                  <option value={2}>Service 2</option>
                  <option value={3}>Service 3</option>
                </select>
              </div> */}
              <div className="col-12">
                <textarea className="form-control bg-light border-0" rows={3} placeholder="Message" name="message" defaultValue={""}  required />
              </div>
              <div className="col-12">
                <button className="btn btn-dark w-100 py-3" type="submit" value='send' disabled={isSubmitting}>  {isSubmitting ? 'Sending...' : 'Submit'}</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>

<ToastContainer/>

    </>
  )
}
