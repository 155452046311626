import React from 'react'
import Header from '../../Component/navComponent/nav'
import Service from '../../Component/ServiceComponent/service'
import Footer from '../../Component/footerComponent/footer'
import About from '../../Component/AboutComponent/about'
import Feature from '../../Component/featureComponent/feature'
import Home from '../../Component/homeComponent/home'
import Fact from '../../Component/factComponent/fact'
import Contact from '../../Component/ContactComponent/contact'

export default function Homepage() {
  return (
    <>
    
    <Home/>
    <Service/>
    <Fact/>
    <About/>
    <Feature/>
    <Contact/>
    </>
  )
}
