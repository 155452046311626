import React, { useEffect, useRef, useState } from 'react'
import { Carousel } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './home.css'
import img from '../../Img/carousel-1.jpg'
import img1 from '../../Img/carousel-2.jpg'
import img2 from '../../Img/aka.avif'
import { toast } from 'react-toastify';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import emailjs from '@emailjs/browser';



export default function Home() {

  const [showContactBox, setShowContactBox] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

 


  useEffect(() => {
    if (showContactBox) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }

    // Cleanup function
    return () => {
      document.body.classList.remove('no-scroll');
    };
  }, [showContactBox]);

  const handleLinkClick = () => {
    
    setShowContactBox(true);
    
  };


  const handleCloseContactBox = () => {
    setShowContactBox(false);
   
  };

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
   

    emailjs
      .sendForm('service_o7y72jj', 'template_zmozbsl', form.current, {
        publicKey: '_ybywiWeaDGGYODeo',
      })
      .then(
        () => {
          console.log('SUCCESS!');
          toast.success('Message sent successfully!', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          })
          e.target.reset()
        },
        (error) => {
          console.log('FAILED...', error.text);
          toast.error('Failed to send message. Please try again.', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        },
      )
      .finally(() => {
        setIsSubmitting(false);
      });

  };




  return (<>

<div className={`contact-box ${showContactBox ? 'show' : ''}`}>
  
  <div className="contact-box-content text-center">

    <div className="col-lg-0">
      
    <div className="bg-primary rounded h-100 d-flex align-items-center p-5 " data-aos='zoom-in'>
   
            <button className='close' onClick={handleCloseContactBox}><AiOutlineCloseCircle/></button>

      <form ref={form} onSubmit={sendEmail}>
        <div className="row g-3">
        <h2>Contact Us</h2>
          <div className="col-xl-12">
            <input type="text" className="form-control bg-light border-0" placeholder="Your Name" name="from_name" style={{height: 55}} required />
          </div>
          <div className="col-12">
            <input type="email" className="form-control bg-light border-0" placeholder="Your Email" name="from_email" style={{height: 55}} required />
          </div>
          <div className="col-12">
            <textarea className="form-control bg-light border-0" rows={3} placeholder="Message" name="message" defaultValue={""}  required/>
          </div>
          <div className="col-12">
            <button className="btn btn-success w-100 py-3" type="submit" value='send' disabled={isSubmitting}>  {isSubmitting ? 'Sending...' : 'Submit'}</button>
          </div>
        </div>
      </form>
    </div>
  </div>
  
  </div>
</div>


    <div className='home'>
  <Carousel fade id="header-carousel">
    <Carousel.Item>
      <div className="carousel-image-container">
        <img src={img} alt="First slide" />
      </div>
      <Carousel.Caption className="carousel-caption d-flex flex-column align-items-center justify-content-center">
        <div className="p-3 text-center">
          <h5 className="text-white text-uppercase mb-3 animated slideInDown">Adapt &amp; Succeed</h5>
          <h1 className="display-4 text-white mb-md-4 animated zoomIn">Creative &amp; Innovative Digital Solution</h1>
          <Link to="/services" className="btn btn-primary py-2 px-4 me-3 animated slideInLeft">Services</Link>
          <Link  className="btn btn-outline-light py-2 px-4 animated slideInRight" onClick={handleLinkClick}>Contact Us</Link>
        </div>
      </Carousel.Caption>
    </Carousel.Item>
    <Carousel.Item>
      <div className="carousel-image-container">
        <img src={img1} alt="Second slide" />
      </div>
      <Carousel.Caption className="carousel-caption d-flex flex-column align-items-center justify-content-center">
        <div className="p-3 text-center">
          <h5 className="text-white text-uppercase mb-3 animated slideInDown">Innovative &amp; Excellence</h5>
          <h1 className="display-4 text-white mb-md-4 animated zoomIn">Develop a Unique Value Proposition</h1>
          <Link to="/services" className="btn btn-primary py-2 px-4 me-3 animated slideInLeft">Services</Link>
          <Link  className="btn btn-outline-light py-2 px-4 animated slideInRight" onClick={handleLinkClick} >Contact Us</Link>
        </div>
      </Carousel.Caption>
    </Carousel.Item>
  </Carousel>
</div>
</>
  )
}
