import { faShieldHalved } from '@fortawesome/free-solid-svg-icons'
import React from 'react'
import { RiEdgeNewFill } from 'react-icons/ri'
import Aos from 'aos'
import 'aos/dist/aos.css'
import { GiStairsGoal } from 'react-icons/gi'

export default function Feature() {
  return (
    <div>
<div className="container-fluid py-5" data-aos='zoom-in'  data-aos-delay="400" >
  <div className="container py-5">
    <div className="section-title text-center position-relative pb-3 mb-5 mx-auto" style={{maxWidth: 600}}>
      <h5 className="fw-bold text-primary text-uppercase">Why Choose Us</h5>
      <h1 className="mb-0"> Built on Innovation, Expertise, and You</h1>
    </div>
    <div className="row g-5">
      <div className="col-lg-4">
        <div className="row g-5">
          <div className="col-12 " data-aos='zoom-in'  data-aos-delay="800">
            <div className="bg-primary rounded d-flex align-items-center justify-content-center mb-3" style={{width: 60, height: 60}}>
            <RiEdgeNewFill className='text-white'/>
            </div>
            <h4>Cutting-Edge Solutions</h4>
            <p className="mb-0">We leverage the latest technologies to deliver innovative IT solutions that drive your business forward.</p>
          </div>
          <div className="col-12 " data-aos='zoom-in'  data-aos-delay="600">
            <div className="bg-primary rounded d-flex align-items-center justify-content-center mb-3" style={{width: 60, height: 60}}>
            <GiStairsGoal className='text-white'/>

            </div>
            <h4> Future-Focused Approach </h4>
            <p className="mb-0">As a startup, we're agile and adaptable, always looking ahead to anticipate and meet your evolving IT needs.</p>
          </div>
        </div>
      </div>
      <div className="col-lg-4  wow zoomIn" data-wow-delay="0.9s" style={{minHeight: 350}}>
        <div className="position-relative h-100">
          <img className="position-absolute w-100 h-100 rounded wow zoomIn" data-wow-delay="0.1s" src="img/feature.jpg" style={{objectFit: 'cover'}} />
        </div>
      </div>
      <div className="col-lg-4">
        <div className="row g-5">
          <div className="col-12 wow" data-aos='zoom-in'  data-aos-delay="800">
            <div className="bg-primary rounded d-flex align-items-center justify-content-center mb-3" style={{width: 60, height: 60}}>
              <i className="fa fa-users-cog text-white" />
            </div>
            <h4>Expert Team
            </h4>
            <p className="mb-0">Our passionate professionals bring fresh perspectives and diverse expertise to tackle your unique challenges.
            .</p>
          </div>
          <div className="col-12 " data-aos='zoom-in'  data-aos-delay="600">
            <div className="bg-primary rounded d-flex align-items-center justify-content-center mb-3" style={{width: 60, height: 60}}>
              <i className="fa fa-phone-alt text-white" />
            </div>
            <h4>Responsive Support</h4>
            <p className="mb-0">We're committed to your success with quick, personalized support - because your growth is our priority.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

    </div>
  )
}
