import React, { useEffect } from 'react'
import Aos from 'aos'
import 'aos/dist/aos.css'
import { BsAndroid, BsAndroid2 } from 'react-icons/bs'
import { MdAttachEmail, MdCode, MdSecurity, MdTerminal } from 'react-icons/md'
import { GiNewspaper } from 'react-icons/gi'
import { Link } from 'react-router-dom'


export default function Service() {

  useEffect(()=>
  {
     Aos.init()
  },[]) 

  return (
    <div>
     <div className="container-fluid py-5 wow "  >
  <div className="container py-5">
    <div className="section-title text-center position-relative pb-3 mb-5 mx-auto" style={{maxWidth: 600}}>
      <h5 className="fw-bold text-primary text-uppercase" data-aos='fade-right'>Our Services</h5>
      <h1 className="mb-0"  data-aos='fade-right'>Technology & Solutions Services</h1>
    </div>
    <div className="row g-5">
      <div className="col-lg-4 col-md-6 " data-aos='zoom-in' data-aos-delay="300" >
        <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
          <div className="service-icon">
          <i><MdCode className='text-white'/></i> 

          </div>
          <h4 className="mb-3">Web Development</h4>
          <p className="m-0">We design and develop custom software solutions that meet your business needs.</p>
          <a className="btn btn-lg btn-primary rounded" href>
            <i className="bi bi-arrow-right" />
          </a>
        </div>
      </div>
      <div className="col-lg-4 col-md-6"  data-aos='zoom-in' data-aos-delay="400">
        <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
          <div className="service-icon">
          <i><BsAndroid2 className='text-white'/></i> 
          </div>
          <h4 className="mb-3">Apps Development</h4>
          <p className="m-0">We create mobile apps that are fast, secure, and user-friendly.</p>
          <a className="btn btn-lg btn-primary rounded" href>
            <i className="bi bi-arrow-right" />
          </a>
        </div>
      </div>
      <div className="col-lg-4 col-md-6 " data-aos='zoom-in' data-aos-delay="500">
        <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
          <div className="service-icon">
          <i><MdSecurity className='text-white'/></i> 

          </div>
          <h4 className="mb-3">Cyber Security</h4>
          <p className="m-0">Cyber security is how individuals and organisations reduce the risk of cyber attack</p>
          <a className="btn btn-lg btn-primary rounded" href>
            <i className="bi bi-arrow-right" />
          </a>
        </div>
      </div>
      <div className="col-lg-4 col-md-6 "  data-aos='zoom-in' data-aos-delay="300">
        <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
          <div className="service-icon">
         <i><GiNewspaper className='text-white'/></i> 
          </div>
          <h4 className="mb-3">E Commerce</h4>
          <p className="m-0">E-commerce development is the process of building online platforms that allow businesses to sell products or services</p>
          <a className="btn btn-lg btn-primary rounded" href>
            <i className="bi bi-arrow-right" />
          </a>
        </div>
      </div>
      <div className="col-lg-4 col-md-6" data-aos='zoom-in' data-aos-delay="400">
        <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
          <div className="service-icon">
            <i className="fa fa-search text-white" />
          </div>
          <h4 className="mb-3">SEO Optimization</h4>
          <p className="m-0">SEO Optimization for an IT company is crucial to improve your search engine results page high rankings, build trust and authority.</p>
          <a className="btn btn-lg btn-primary rounded" href>
            <i className="bi bi-arrow-right" />
          </a>
        </div>
      </div>
      <div className="col-lg-4 col-md-6 "  data-aos='zoom-in' data-aos-delay="500">
        <div className="position-relative bg-primary rounded h-100 d-flex flex-column align-items-center justify-content-center text-center p-5">
        
          <h3 className="text-white mb-3">24/7 Support</h3>
          <p className="text-white mb-3"> Whether you have questions, feedback, or just want to say hello, our team is here to assist you</p>
          <b><p><Link to="mailto:info@makeoversolution.in" className='text-white' >support@makeoversolution.in</Link></p></b>
        </div>
      </div>
    </div>
  </div>
</div>


    </div>
  )
}
