import React, { useState, useEffect, useRef } from 'react'
import 'aos/dist/aos.css'
import Aos from 'aos'
import { Link } from 'react-router-dom'
import './about.css'
import emailjs from '@emailjs/browser';
import { toast, ToastContainer } from 'react-toastify'
import { AiOutlineCloseCircle } from 'react-icons/ai'


export default function About() {
  const [showContactBox, setShowContactBox] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);


  useEffect(() => {
    Aos.init({  });
  }, []);

  useEffect(() => {
    if (showContactBox) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }

    // Cleanup function
    return () => {
      document.body.classList.remove('no-scroll');
    };
  }, [showContactBox]);

  const handleLinkClick = () => {
    setShowContactBox(true);
    
  };


  const handleCloseContactBox = () => {
    setShowContactBox(false);
   
  };

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
   

    emailjs
      .sendForm('service_o7y72jj', 'template_zmozbsl', form.current, {
        publicKey: '_ybywiWeaDGGYODeo',
      })
      .then(
        () => {
          console.log('SUCCESS!');
          toast.success('Message sent successfully!', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          })
          e.target.reset()
        },
        (error) => {
          console.log('FAILED...', error.text);
          toast.error('Failed to send message. Please try again.', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        },
      )
      .finally(() => {
        setIsSubmitting(false);
      });

  };



 
  return (
    <>
 
   
    <div className={`contact-box ${showContactBox ? 'show' : ''}`}>
  
      <div className="contact-box-content text-center">

        <div className="col-lg-0">
          
        <div className="bg-primary rounded h-100 d-flex align-items-center p-5 " data-aos='zoom-in'>
       
                <button className='close' onClick={handleCloseContactBox}><AiOutlineCloseCircle /></button>

          <form ref={form} onSubmit={sendEmail}>
            <div className="row g-3">
            <h2>Contact Us</h2>
              <div className="col-xl-12">
                <input type="text" className="form-control bg-light border-0" placeholder="Your Name" name="from_name" style={{height: 55}} required />
              </div>
              <div className="col-12">
                <input type="email" className="form-control bg-light border-0" placeholder="Your Email" name="from_email" style={{height: 55}} required />
              </div>
              <div className="col-12">
                <textarea className="form-control bg-light border-0" rows={3} placeholder="Message" name="message" defaultValue={""}  required/>
              </div>
              <div className="col-12">
                <button className="btn btn-success w-100 py-3" type="submit" value='send' disabled={isSubmitting}>  {isSubmitting ? 'Sending...' : 'Submit'}</button>
              </div>
            </div>
          </form>
        </div>
      </div>
      
      </div>
    </div>

    

   


<div className="container-fluid pt-5">
  <div className="container py-5">
    <div className="row g-5">
      <div className="col-lg-7" data-aos='zoom-in'  data-aos-delay="500" >
        <div className="section-title position-relative pb-3 mb-5">
          <h5 className="fw-bold text-primary text-uppercase">About Us</h5>
          <h1 className="mb-0">Our mission is to deliver Innovative Software Solutions that empower businesses</h1>
        </div>
        <p className="mb-4">At <b> MAKEOVER SOLUTIONS</b>, our mission is to deliver innovative software solutions that empower businesses and individuals to achieve their full potential. We strive to create technology that is not only powerful and efficient but also user-friendly and accessible.</p>
        <div className="row g-0 mb-3">
          <div className="col-sm-6 ">
            <h5 className="mb-3"><i className="fa fa-check text-primary me-3" />Certified Experts</h5>
            <h5 className="mb-3"><i className="fa fa-check text-primary me-3" />Proactive Support</h5>
          </div>
          <div className="col-sm-6 ">
            <h5 className="mb-3"><i className="fa fa-check text-primary me-3" />Customized Solutions</h5>
            <h5 className="mb-3"><i className="fa fa-check text-primary me-3" />The Art of Infrastructure</h5>
          </div>
        </div>
        <div className="d-flex align-items-center mb-4">
          <div className="bg-primary d-flex align-items-center justify-content-center rounded" style={{width: 60, height: 60}}>
          <i className="fas fa-envelope text-white" />

          </div>
          <div className="ps-4"  data-aos='zoom-in'  data-aos-delay="300">
            <h5 className="mb-2">Email to ask any question</h5>
            <Link to="mailto:info@makeoversolution.in" className="text-decoration-none">
    <h4 className="text-primary mb-0">info@makeoversolution.in</h4>
  </Link>
          </div>
        </div>
        <Link to="" className="btn btn-primary py-3 px-5 mt-3 " onClick={handleLinkClick} data-aos='zoom-in' data-aos-delay="400">Submit A Request</Link>
      </div>
      <div className="col-lg-5" style={{minHeight: 500}}>
        <div className="position-relative h-100">
          <img className="position-absolute w-100 h-100 rounded " data-wow-delay="0.9s" src="img/about.jpg" style={{objectFit: 'cover'}} />
        </div>
      </div>
    </div>
  </div>
</div>
<ToastContainer/>

    </>
  )
}
