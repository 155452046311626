import React, { useState, useEffect } from 'react';
import img from '../../Img/mklogo11.png'
 import { Link, useLocation } from 'react-router-dom';
import './nav.css'

export default function Nav() {
  const [isNavCollapsed, setIsNavCollapsed] = useState(true);
  const [headerStyle, setHeaderStyle] = useState({
    color: 'white',
    backgroundColor: 'transparent',
    transition: 'background-color 0.3s ease, color 0.3s ease',
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    zIndex: 1000
  });

  const location = useLocation();

  const handleLinkClick = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    setIsNavCollapsed(true);
  };

  const handleNavCollapse = () => {
    setIsNavCollapsed(!isNavCollapsed);
    document.body.classList.toggle('no-scroll', isNavCollapsed);
  };

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      if (location.pathname === '/') {
        if (currentScrollY < 150) {
          setHeaderStyle(prevStyle => ({
            ...prevStyle,
            backgroundColor: 'transparent',
           
          }));
        } else {
          setHeaderStyle(prevStyle => ({
            ...prevStyle,
            backgroundColor: '#011c29f3',
            color: 'white'
          }));
        }
      } else {
        setHeaderStyle(prevStyle => ({
          ...prevStyle,
          backgroundColor: '#011c29f3',
          color: 'white'
        }));
      }
    };

    handleScroll();
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => window.removeEventListener('scroll', handleScroll);
  }, [location.pathname]);

  useEffect(() => {
    return () => {
      document.body.classList.remove('no-scroll');
    };
  }, []); 

  return (
    <div className="container-fluid position-relative p-0">
    <nav className="navbar navbar-expand-lg navbar-dark py-3" style={headerStyle}>
      <div className="container">
        <Link to="/" className="navbar-brand p-0">
          <img src={img} alt="Logo" className="logo-img" onClick={handleLinkClick} />
        </Link>
        <button 
          className={`navbar-toggler custom-toggler ${!isNavCollapsed ? 'open' : ''}`}
          type="button" 
          onClick={handleNavCollapse} 
          aria-label="Toggle navigation"
          aria-expanded={!isNavCollapsed}
        >
          <span></span>
          <span></span>
          <span></span>
        </button>
        <div className={`collapse navbar-collapse ${!isNavCollapsed ? 'show' : ''}`} id="navbarCollapse">
          <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
            <li className="nav-item">
              <Link to="/" className="nav-link" onClick={handleLinkClick}>Home</Link>
            </li>
            <li className="nav-item">
              <Link to="/about" className="nav-link" onClick={handleLinkClick}>About</Link>
            </li>
            <li className="nav-item">
              <Link to="/services" className="nav-link" onClick={handleLinkClick}>Services</Link>
            </li>
            <li className="nav-item">
              <Link to="/contact" className="nav-link" onClick={handleLinkClick}>Contact us</Link>
            </li>
            {/* <li className="nav-item">
          <Link to='/'  className="btn btn-primary   d-lg-inline-block nav-link">
            Products
          </Link>
          </li> */}
          </ul>

        </div>
      </div>
    </nav>
  </div>
  );
}