import React from "react";

import { Link } from "react-router-dom";
import './footer.css'

export default function Footer() {
  const handleLinkClick = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <div
      className="container-fluid bg-dark text-light mt-3 pt-3 "
      data-aos="fade-up"
      data-aos-delay="100" >
      <div className="container">
        <div className="row footer-row" >
          <div className="col-lg-4 col-md-6 col-sm-12 mb-3 footer-column">
            {/* <div className="col-lg-4 col-md-12 pt-5 pt-lg-4 mb-3"> */}
            <div className="section-title section-title-sm position-relative pb-3 mb-4">
              <h3 className="text-light mb-0">Quick Links</h3>
            </div>
            <div className="link-animated d-flex flex-column justify-content-start">
              <Link
                className="text-light mb-3"
                onClick={handleLinkClick}
                to="/"
              >
                <i className="bi bi-arrow-right text-primary me-2" />
                Home
              </Link>
              <Link
                className="text-light mb-3"
                onClick={handleLinkClick}
                to="/about"
              >
                <i className="bi bi-arrow-right text-primary me-2" />
                About
              </Link>
              <Link
                className="text-light mb-3"
                onClick={handleLinkClick}
                to="/services"
              >
                <i className="bi bi-arrow-right text-primary me-2" />
                Our Services
              </Link>
              {/* <Link className="text-light mb-2" to="#"><i className="bi bi-arrow-right text-primary me-2" />Meet The Team</Link>
                <Link className="text-light mb-2" to="#"><i className="bi bi-arrow-right text-primary me-2" />Latest Blog</Link> */}
              <Link
                className="text-light mb-3"
                onClick={handleLinkClick}
                to="/contact"
              >
                <i className="bi bi-arrow-right text-primary me-2" />
                Contact Us
              </Link>
            </div>
            {/* </div> */}
          </div>

          <div className="col-lg-4 col-md-6 col-sm-12 footer-column">
            {/* <div className="col-lg-4 col-md-12 pt-4 mb-2"> */}
            <div className="section-title section-title-sm position-relative pb-3 mb-4">
              <h3 className="text-light mb-0">Get In Touch</h3>
            </div>
            <div className="d-flex mb-3">
              <i className="bi bi-geo-alt text-primary me-2" />
              <p className="mb-0" >
                Ratan Lok Colony <br/>Vijay Nagar, Indore [M.P.]
              </p>
            </div>
            <div className="d-flex mb-3">
              <i className="bi bi-envelope-open text-primary me-2"  />
              <Link to="mailto:info@makeoversolution.in" className="text-decoration-none">
    <p className="text-white">info@makeoversolution.in</p>
  </Link>
            </div>
            <div className="d-flex mb-3">
              <i className="bi bi-telephone text-primary me-2" />
              <p className="mb-0">+91 9238274003</p>
            </div>

            {/* </div> */}
          </div>

          <div className="col-lg-4 col-md-6 col-sm-12 pb-6 footer-column">
            {/* <div className="col-lg-4 col-md-12 pt-4 mb-3"> */}
            <div className="section-title section-title-sm position-relative pb-3 mb-4">
              <h3 className="text-light mb-0 ">Social Links</h3>
            </div>
            <div className="d-flex">
              <div className=" mb-2 ">
                <Link
                  className="btn btn-primary btn-square me-2"
                  to="http://www.linkedin.com/in/makeoversolutionltd" target="_blank"
                >
                  <i className="fab fa-linkedin fw-normal" />
                </Link>
              </div>
              <div className=" mb-2">
                <Link
                  className="btn btn-primary btn-square me-2"
                  to="https://www.facebook.com/people/Vipin-Tiwari/61555952893020/?ref=xav_ig_profile_web"
                  target="_blank"
                >
                  <i className="fab fa-facebook fw-normal" />
                </Link>
              </div>
              <div className=" mb-2">
                <Link
                  className="btn btn-primary btn-square me-2"
                  to="https://www.instagram.com/makeoversolutionsltd"
                  target="_blank"
                >
                  <i className="fab fa-instagram fw-normal" />
                </Link>
              </div>
              <div className="mb-2">
                <Link
                  className="btn btn-primary btn-square me-2"
                  to="https://x.com/Bookmakeover?t=z7QBtTWWiZ8jFSlYp1hTvg&s=09"
                  target="_blank"
                >
                  <i className="fab fa-twitter fw-normal" />
                </Link>
              </div>
            </div>

            {/* </div> */}
          </div>

         
        </div>
      </div>
      <div
            className="container-fluid text-white"
            style={{ background: "#061429" }}
          >
            <div className="container text-center">
              <div className="row justify-content-end">
                <div className="col-lg-8 ">
                  <div
                    className="d-flex align-items-center "
                    style={{ height: 50 }}
                  >
                    <p className="mb-0">
                      {" "}
                      <Link className="text-white col-sm " to="">
                        © 2024 Makeover Solutions. All rights reserved
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
    </div>
  );
}
