import React from 'react'
import Aos from 'aos'
import 'aos/dist/aos'
import './fact.css'
import { RiCustomerService2Fill } from 'react-icons/ri'

export default function Fact() {
  return (
    <div className="container-fluid facts py-5 pt-lg-6">
  <div className="container py-5 pt-lg-0">
    <div className="row gx-0">
      <div className="col-lg-4" data-aos='zoom-in' data-aos-delay="300">
        <div className="bg-primary shadow d-flex align-items-center justify-content-center p-4" style={{height: 150}}>
          <div className="bg-white d-flex align-items-center justify-content-center rounded mb-2" style={{width: 60, height: 60}}>
            <i className="fa fa-users text-primary" />
          </div>
          <div className="ps-4">
            <h5 className="text-white mb-0">Daily innovation for growth</h5>
            <h1 className="text-white mb-0" data-toggle="counter-up"></h1>
          </div>
        </div>
      </div>
      <div className="col-lg-4 " data-aos='zoom-in' data-aos-delay="400" >
        <div className="bg-light shadow d-flex align-items-center justify-content-center p-4" style={{height: 150}}>
          <div className="bg-primary d-flex align-items-center justify-content-center rounded mb-2" style={{width: 60, height: 60}}>
            <i className="fa fa-check text-white" />
          </div>
          <div className="ps-4">
            <h5 className="text-primary mb-0">Dedicated to driving growth and efficiency</h5>
            <h1 className="mb-0" data-toggle="counter-up"></h1>
          </div>
        </div>
      </div>
      <div className="col-lg-4 " data-aos='zoom-in' data-aos-delay="500">
        <div className="bg-primary shadow d-flex align-items-center justify-content-center p-4" style={{height: 150}}>
          <div className="bg-white d-flex align-items-center justify-content-center rounded mb-2" style={{width: 60, height: 60}}>
          <i><RiCustomerService2Fill  className='text-blue'/></i>
          </div>
          <div className="ps-4">
            <h5 className="text-white mb-0">Customer support </h5>
            <h1 className="text-white mb-0" data-toggle="counter-up">24/7</h1>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

  )
}
