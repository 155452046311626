import logo from './logo.svg';
import './App.css';
import Nav from './Component/navComponent/nav';
import Header from './Component/headerComponent/header';
import Topbar from './Component/topbarComponent/topbar';
import About from './Component/AboutComponent/about';
import Feature from './Component/featureComponent/feature';
import Service from './Component/ServiceComponent/service';
import Contact from './Component/ContactComponent/contact';
import Footer from './Component/footerComponent/footer';
import { Route, Routes } from 'react-router-dom';
import Home from './Pages/Homepage/Homepage';
import Homepage from './Pages/Homepage/Homepage';
import Fact from './Component/factComponent/fact';

function App() {
  return <>
  <Nav/>
  <Routes>
 <Route path='/' element={<Homepage/>}></Route>
 <Route path='/about' element={<About/>}></Route>
 <Route path='/services' element={<Service/>}></Route>
 <Route path='/footer' element={<Footer/>}></Route>
 <Route path='/contact' element={<Contact/>}></Route>
  </Routes>

<Footer/>
  

  </>

 
  
  

}

export default App;
